<template>
  <v-menu
    v-model="isOpen"
    v-bind="$attrs"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    max-width="290px"
    :z-index="zIndex"
  >
    <template #activator="{on}">
      <v-text-field
        ref="datePicker"
        v-model="dateValue"
        prepend-inner-icon="mdi-calendar"
        class="datePicker-field"
        outlined
        required
        dense
        :label="labelValue"
        :rules="rules"
        :clearable="clearable"
        :disabled="disabled"
        :hide-details="hideDetails"
        v-on="on"
        @keypress="onlyNumbers"
      />
    </template>
    <v-date-picker
      v-model="internalDate"
      color="blue darken-1"
      no-title
      :locale="locale"
      :first-day-of-week="1"
      :allowed-dates="allowedDates"
      year-icon="mdi-calendar-blank"
      :range="range"
      :max="max"
      :min="minDate"
      @input="changedDateValue"
    />
  </v-menu>
</template>

<script>
import {mapState} from 'vuex'
import onlyNumbers from '@/helpers/onlyNumbers'

export default {
  name: 'DatePickerWithField',
  props: {
    value: {
      type: [Date, String, Array],
      default: '',
    },
    range: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    rules: {
      type: Array,
      default: () => [],
    },
    max: {
      type: String,
      default: null,
    },
    min: {
      type: String,
      default: null,
    },
    allowedDates: {
      type: Function,
      default: null,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    zIndex: {
      type: [String, Number],
      default: '8',
    },
  },
  data() {
    return {
      isOpen: false,
      internalDate: this.value,
      internalDateValue: this.value,
      minDate: '',
    }
  },
  computed: {
    ...mapState({
      locale: (state) => state.locale,
    }),
    dateValue: {
      get() {
        return this.internalDateValue
      },
      set(newValue) {
        const length = this.range ? 22 : 11
        if (newValue && newValue.length < length) {
          if (this.range) {
            this.setDateWithRange(newValue)
          } else {
            this.setDateWithoutRange(newValue)
          }
        } else {
          this.internalDate = null
          this.internalDateValue = null
          this.inputDateValue(this.internalDate)
        }
      },
    },
    labelValue() {
      return this.rules.length ? this.$t(this.label) + ' *' : this.$t(this.label)
    },
  },
  watch: {
    value(val) {
      if (!val) this.minDate = ''
      this.internalDate = val
      this.internalDateValue = this.range ? val.join('~') : val
    },
  },
  methods: {
    onlyNumbers,
    // Формирование даты без диапазона
    setDateWithoutRange(newValue) {
      if (newValue.length === 4 || newValue.length === 7) {
        this.internalDateValue = newValue + '-'
        this.internalDate = null
      } else if (newValue.length === 10) {
        let newDateObject = new Date(newValue)
        if (this.isValidDate(newDateObject)) {
          if (this.max !== null && new Date(this.max).getTime() < newDateObject.getTime()) {
            this.internalDate = this.max
            this.internalDateValue = this.max
          } else {
            this.internalDate = newValue
            this.internalDateValue = newValue
          }
        } else {
          this.resetDateValue()
        }
        this.inputDateValue(this.internalDate)
        this.close()
      } else {
        this.internalDateValue = newValue
        this.internalDate = null
      }
    },
    // Формирование даты с диапазоном
    setDateWithRange(newValue) {
      if (
        newValue.length === 4 ||
        newValue.length === 7 ||
        newValue.length === 15 ||
        newValue.length === 18
      ) {
        this.internalDateValue = newValue + '-'
      } else if (newValue.length === 10) {
        let newDateObject = new Date(newValue)
        if (this.isValidDate(newDateObject)) {
          if (this.max !== null && new Date(this.max).getTime() < newDateObject.getTime()) {
            this.internalDate = this.max
            this.internalDateValue = this.max
          } else {
            this.minDate = newValue
            this.internalDate = [newValue]
            this.internalDateValue = newValue + '~'
          }
        } else {
          this.resetDateValue()
        }
      } else if (newValue.length === 21) {
        const [firstDate, secondDate] = newValue.split('~')
        let minDateObject = new Date(firstDate)
        let newDateObject = new Date(secondDate)
        if (this.isValidDate(newDateObject)) {
          if (!!minDateObject && minDateObject.getTime() <= newDateObject.getTime()) {
            this.internalDate = [newValue]
            this.internalDateValue = newValue
            this.inputDateValue(newValue.split('~'))
            this.close()
          } else {
            this.resetDateValue()
            this.close()
          }
        } else {
          this.resetDateValue()
          this.close()
        }
      } else {
        this.internalDateValue = newValue
      }
    },
    //Проверка на валидность даты
    isValidDate(val) {
      if (Object.prototype.toString.call(val) === '[object Date]') {
        return !isNaN(val.getTime())
      } else {
        return false
      }
    },
    //Действия при изменении значения даты
    changedDateValue() {
      if (this.range) {
        const [date] = this.internalDate
        this.minDate = date
        this.internalDateValue = date
        if (this.internalDate.length === 2) {
          this.inputDateValue(this.internalDate)
          this.close()
        }
      } else {
        this.inputDateValue(this.internalDate)
        this.close()
      }
    },
    inputDateValue(dates) {
      this.$emit('input', dates)
    },
    resetDateValue() {
      this.internalDate = null
      this.internalDateValue = null
      this.$refs.datePicker.internalValue = null
    },
    close() {
      this.isOpen = false
      this.minDate = ''
    },
  },
}
</script>

<style lang="scss">
.v-text-field.datePicker-field:not(.v-input--is-disabled) {
  .v-text-field__slot input {
    cursor: pointer;
  }

  .v-input__prepend-inner .v-input__icon--prepend-inner .v-icon {
    font-size: 18px;
  }
}
</style>
